<template>
    <v-app style="height: 30%;" dark>
        <v-card>
            <v-card-title>
                Resultado de pago
            </v-card-title>
            <v-card-subtitle v-if="detail.estado === approved">
                Felicitaciones tu pago ha sido efectuado correctamente
            </v-card-subtitle>

            <b-container>
                <b-row>
                    <b-col cols="1"><h6>Estado</h6></b-col>
                    <b-col><p>{{detail.estado}}</p></b-col>
                </b-row>
                <b-row>
                    <b-col cols="1"><h6>Referencia</h6></b-col>
                    <b-col><p>{{detail.referencia}}</p></b-col>
                </b-row>
                <b-row>
                    <b-col cols="1"><h6>Fecha</h6></b-col>
                    <b-col><p>{{detail.p2pfecha}}</p></b-col>
                </b-row>
            </b-container>
            <div class="text-center">
                <v-btn class="mx-2" dark large color="info" v-if="detail.estado === approved">
                    Imprimir contrato
                    <v-icon right dark>fa-print</v-icon>
                </v-btn>
                <v-btn class="mx-2" dark large color="indigo" v-if="detail.estado === approved">
                    Enviar por email
                    <v-icon right dark>fa-envelope</v-icon>
                </v-btn>
                <v-btn class="mx-2" dark large color="success" @click="navigate('transaction')">
                    Ver mis contratos
                    <v-icon right dark>fa-list</v-icon>
                </v-btn>
                <v-btn class="mx-2" dark large color="purple" @click="navigate('contract-select')">
                    Nuevo documento
                    <v-icon right dark>mdi-plus</v-icon>
                </v-btn>
            </div>
            <br>
        </v-card>

    </v-app>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

    export default {
        name: "Result",
        data() {
            return {
                detail: {},
                approved: 'APROBADO',
                rejected: 'RECHAZADO',
                pending: 'PENDIENTE',
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Resultado de pago"}
            ]);

            if (this.$route.params.detail) {
                this.detail = this.$route.params.detail;
            } else {
                this.$router.push('/transaction')
            }
        },
        methods: {
            navigate(path) {
                this.$router.push({name: path})
            }
        },
        computed: {
            status() {
                return this.detail && this.detail.status && this.detail.status.status === this.approved ? 'success' : 'warning'
            }
        }
    }
</script>

<style scoped>
    .row {
        height: 30px !important;
    }
</style>
